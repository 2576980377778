<template>
  <el-upload
    class="avatar-uploader"
    :action="uploadUrl"
    :show-file-list="false"
    accept=".png,.jpeg,.jpg"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload">
    <img v-if="imageUrl" :src="imageUrl" class="avatar"/>
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>
<script>
  export default {
    props: {
      imageUrl: [String],
      action: String,
    },
    data() {
      return {
        uploadUrl:window.config.uploadUrl,
      };
    },
    watch:{
      action:{
        handler(pramas) {
          console.log(27,pramas);
          this.uploadUrl="http://218.59.172.200:8889/"+pramas;
        },
        deep:true,
        immediate:true,
      },
      url:{
        handler(pramas) {
          this.downloadUrl=pramas;
        },
        deep:true,
        immediate:true,
      },
    },
    methods: {
      handleAvatarSuccess(res) {
        this.imageUrl = "http://218.59.172.200:8889/"+res.url;
        this.$emit("save-success",this.imageUrl);

      },
      beforeAvatarUpload(file) {
        console.log(file,48);
        const isImage = (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg");
        // const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isImage) {
          this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
        }
        // if (!isLt2M) {
        //   this.$message.error("上传头像图片大小不能超过 2MB!");
        // }
        // return isImage && isLt2M;
        return isImage;
      },
    },
  };
</script>
<style lang="scss">
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 120px;
    height: 120px;
    line-height: 120px;
    text-align: center;
  }

  .avatar {
    width: 120px;
    height: 120px;
    display: block;
  }
</style>
