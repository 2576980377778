<template>
  <el-dialog
    v-el-drag-dialog
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :title="model.id?'编辑图片':'新增图片'"
    width="500px"
    top="3vh"
    :modal="false"
    @close="$reset('form')">
    <el-form
      ref="form"
      v-loading="contentLoading"
      :model="model"
      class="form"
      :label-width="$l('device.labelWidth','80px')">
      <el-form-item label="图片" prop="route">
        <vm-upload-image
          :image-url="model.route"
          action="basic/upload/single-file"
          @save-success="setPic"></vm-upload-image>
      </el-form-item>
      <el-form-item :label="$l('project.name','客户公司')" prop="businessId">
        <vm-select-input :value="model.businessName" @select="$refs.companySelect.open()"></vm-select-input>
      </el-form-item>
      <el-form-item label="是否使用" prop="isUse">
        <el-select v-model="model.isUse">
          <el-option :value="true" label="是"></el-option>
          <el-option :value="false" label="否"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="dialogVisible=false">{{$l("common.cancel","取消")}}</el-button>
      <el-button :loading="submitLoading" type="primary" @click="handleSubmit">{{$l("common.save","保存")}}</el-button>
    </span>
    <company-select ref="companySelect" @select="companySelect"></company-select>
    <manufacturer-select ref="projectSelect" @select="projectSelect"></manufacturer-select>
  </el-dialog>
</template>
<script>
  import ManufacturerSelect from "./ManufacturerSelect";
  import VmUploadImage from "@/components/VmUploadImage";
  import CompanySelect from "@/views/elevatorUsingCompany/CompanySelect";
  export default {
    components: {VmUploadImage, ManufacturerSelect,CompanySelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        model: {
          type: "property_side",
          isUse: true,
          route:"",
          businessId:"",
        },

      };

    },
    methods: {
      open(id) {
        this.model.id = id;
        this.dialogVisible = true;
        this.getData();
      },
      getData() {
        if (this.model.id) {
          this.contentLoading = true;
          this.$http.get(`other/picture/property/${this.model.id}`).then(data => {
            this.contentLoading = false;
            this.model = data;
            console.log(this.model);
          });
        }
      },
      handleSubmit() {
        this.$refs.form.validate(valid => {
          console.log(valid,75,this.model);
          if (valid) {
            this.submitLoading = true;
            this.$http
              .save("other/picture", this.model)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success("保存成功");
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error("有不符合要求数据，请修改后提交");
          }
        });
      },
      setPic(pic) {
        this.model.route = pic;
      },
      companySelect(row) {
        // this.getRealstate(row.id);
        this.$set(this.model,"businessId", row.id);
        this.$set(this.model,"businessName", row.name);
      },
    },
  };
</script>
<style lang="scss" scoped>
</style>
